import { Cycling } from '@styled-icons/boxicons-regular/';
import { Icon, IconProps } from './Icon';

export function CyclingIcon({ ...props }: IconProps) {
  return (
    <Icon {...props}>
      <Cycling />
    </Icon>
  );
}
